import React from "react";
import BackButton from "./BackButton";
import { useNavigate } from "react-router-dom";
import myGirl from './images/my_girl.webp';


const About = () => {
  const handleClose = () => {
    navigate(-1);
  };
  const navigate = useNavigate();

  return (
    <div style={styles.container}>
      <BackButton onClick={handleClose} />
      <h1 align="center">Om Kritriket</h1>
      <p>
        <strong>Välkommen till Kritriket! </strong><br/>Denna sida skapades med kärlek och omtanke för min yngsta dotter, som
        älskar att pyssla och måla. När hon nu börjar lära sig läsa och skriva,
        ville jag skapa en plats där hennes passion för kreativa aktiviteter
        kunde kombineras med att lära sig läsa.
      </p>
      <p>
        Här kan hon enkelt söka efter och skriva ut bilder att färglägga, eller
        låta fantasin flöda och skapa nya bilder. Detta har gjort hennes lärande
        roligare och mer engagerande, och hjälpt henne att utveckla sina
        färdigheter på ett lekfullt sätt.
      </p>
      <p>
        Genom att kombinera det hon älskar med det hon behöver lära sig för sin
        skolgång, hoppas jag att denna sida blir en värdefull resurs för hennes
        utveckling och glädje.
      </p>
      <p>
        Jag hoppas även att andra föräldrar och deras barn kan få lika mycket
        glädje av sidan som vi har. Må det bli en plats för lärande och kreativitet för många familjer.
      </p>
      <div style={styles.imageContainer}>
        <img src={myGirl} alt="Min dotter" style={styles.image} />
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: "20px",
    padding: "20px",
  },
  imageContainer: {
    display: "flex",
    justifyContent: "center"
  },
  image: {
    marginTop: "20px",
    maxWidth: "70%",
    height: "auto",
    borderRadius: "10px",
    border: "#000 1px solid",
    backgroundColor: "#ffffff",
    padding: "0px",
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.2)"
  }
};

export default About;
