
import { initializeApp } from 'firebase/app';

import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';

import {getFunctions, connectFunctionsEmulator} from 'firebase/functions';

import {getStorage, connectStorageEmulator} from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

import { getAuth, signInWithPopup, GoogleAuthProvider, onAuthStateChanged, signOut  } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyC45V4tsJt22LJPCvwlM11dcncC5NV-dzI",
    authDomain: "kritriket.firebaseapp.com",
    projectId: "kritriket",
    storageBucket: "kritriket.appspot.com",
    messagingSenderId: "69710234026",
    appId: "1:69710234026:web:e09ede1ea226aa5e84abd3",
    measurementId: "G-PM0QZ9PNXQ"
  };

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const firestore = getFirestore(app);
export const functions = getFunctions(app, "https://kritriket.se");
export const storage = getStorage(app);

// Connect to Firebase Emulators in development
if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
    connectFirestoreEmulator(firestore, 'localhost', 8080);
    connectFunctionsEmulator(functions, 'localhost', 5001);
    connectStorageEmulator(storage, 'localhost', 9199);
}

// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();
  
// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({   
    prompt : "select_account "
});
export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);

export const onAuthStateChangedListener = (callback) => onAuthStateChanged(auth, callback);

export const signOutUser = () => signOut(auth);