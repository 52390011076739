import {React, useEffect} from "react";
import Logo from "./images/logo.png";
import SearchComponent from "./SearchComponent";
import Footer from "./Footer";

function Home() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div className="home-container">
      <div className="centered-container">
        <img src={Logo} alt="Logo" className="high-res-img" />
        <SearchComponent />
      </div>
      <Footer />
    </div>
  );
}

export default Home;
