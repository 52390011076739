import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import ImageDetail from "./ImageDetail";
import CategoryDetail from "./CategoryDetail";
import Home from "./Home";
import React from "react";
import TopLeftCorner from "./images/corner_top_left.png";
import TopRightCorner from "./images/corner_top_right.png";
import BottomLeftCorner from "./images/corner_bottom_left.png";
import BottomRightCorner from "./images/corner_bottom_right.png";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import SignIn from "./SignIn";
import PrivacyPolicy from "./PrivacyPolicy";
import ScrollToTop from "./ScrollToTop";
import MemoryGame from "./MemoryGame";
import About from "./About";


function App() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Router>
      <ScrollToTop />

        <div className="App">
          <header className="App-header">
            <Routes>
              <Route path="/login" element={<SignIn />} />
              <Route path="/about" element={<About />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/image/:imageId" element={<ImageDetail />} />
              <Route path="/memory" element={<MemoryGame />} />
              <Route path="/category/:categoryId" element={<CategoryDetail />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </header>
          <img src={TopLeftCorner} alt="illustration" className="top-left no-print" />
          <img src={TopRightCorner} alt="illustration" className="top-right no-print" />
          <img
            src={BottomLeftCorner}
            alt="illustration"
            className="bottom-left no-print"
          />
          <img
            src={BottomRightCorner}
            alt="illustration"
            className="bottom-right no-print"
          />                
        </div>
      </Router>
    </React.Fragment>
  );
}

export default App;
