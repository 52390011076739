import React, { useState, useEffect } from 'react';

const MessageTicker = ({ messages, interval }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        if (prevIndex === messages.length - 1) {
          clearInterval(timer);
          return prevIndex;
        }
        return prevIndex + 1;
      });
    }, interval);

    return () => clearInterval(timer);
  }, [messages.length, interval]); 

  return (
    <div>
      <p>{messages[currentIndex]}</p>
    </div>
  );
};

export default MessageTicker;