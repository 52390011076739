import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import algoliasearch from "algoliasearch/lite";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {Adsense} from '@ctrl/react-adsense';
import {
  InstantSearch,
  Configure,
} from "react-instantsearch-dom";
import SearchHits from "./SearchHits";
import { functions } from "./Firebase";
import { httpsCallable } from "firebase/functions";

const searchClient = algoliasearch(
  "W8A021PY5V",
  "ba504786689c9d4ab73c237cc755bf8d"
);

const SearchComponent = () => {
  const [inputValue, setInputValue] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchQuery(inputValue);
    }
  };

  useEffect(() => {
    if (inputValue === '') {
      setSearchQuery('');
    }
  }, [inputValue]);

  const addNew = async () => {
    if (isLoading || searchQuery === "") return;
    setIsLoading(true);
    const generateImage = httpsCallable(
      functions,
      "ImageController-createImage"
    );

    try {
      const result = await generateImage({ description: inputValue });
      console.log(JSON.stringify(result));
      const destination = `image/${result.data.id}`;
      navigate(destination);
    } catch (err) {
      toast("Kunde inte skapa bilden, prova med en annan beskrivning.", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="search-container">
      
      <InstantSearch
        searchClient={searchClient}
        indexName={process.env.REACT_APP_SEARCH_INDEX}
      >
        <div>
          <input
            type="search"
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleInputKeyPress}
            placeholder="Vad vill du rita?"
          />
        </div>
        <Adsense
        className="AdSlot"
        client="ca-pub-1020157765000788"
        slot="8297430753"
        format="auto"
        responsive="true"
        />
        <div className="image-grid">
          <SearchHits onAddNew={addNew} isLoading={isLoading} />
        </div>
        {searchQuery === '' ? (
          <Configure filters="type:category" hitsPerPage={100}  />
        ) : (
          <Configure query={searchQuery} />
        )}
      </InstantSearch>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
};

export default SearchComponent;
