import React from 'react';  
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BackButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="back-button">
        <ArrowBackIcon fontSize="inherit" />    
    </button>
  );
};

export default BackButton;