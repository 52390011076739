import React, { useState, useEffect } from 'react';
import { firestore, storage } from './Firebase';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';
import './MemoryGame.css';
import './App.css';

const MemoryGame = () => {
  const [cards, setCards] = useState([]);
  const [flippedCards, setFlippedCards] = useState([]);
  const [matchedCards, setMatchedCards] = useState([]);
  const [score, setScore] = useState(0);

  useEffect(() => {
    initializeGame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeGame = async () => {
    const images = await fetchImages();
    const cardValues = [...images, ...images]; // Duplicate images for pairs
    const shuffledCards = shuffleArray(cardValues);
    setCards(shuffledCards);
    setFlippedCards([]);
    setMatchedCards([]);
  };

  const fetchImages = async () => {
    const imagesRef = collection(firestore, 'images');
    const q = query(imagesRef, orderBy('createdAt', 'desc'), limit(3));
    const querySnapshot = await getDocs(q);
    const imageUrls = [];

    for (const doc of querySnapshot.docs) {
      const data = doc.data();
      const imageRef = ref(storage, data.thumbnailImage);
      const url = await getDownloadURL(imageRef);
      imageUrls.push(url);
    }

    return imageUrls;
  };

  const shuffleArray = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  const handleCardClick = (index) => {
    if (flippedCards.length === 2 || flippedCards.includes(index) || matchedCards.includes(index)) {
      return;
    }

    const newFlippedCards = [...flippedCards, index];
    setFlippedCards(newFlippedCards);

    if (newFlippedCards.length === 2) {
      const [firstIndex, secondIndex] = newFlippedCards;
      if (cards[firstIndex] === cards[secondIndex]) {
        setMatchedCards([...matchedCards, firstIndex, secondIndex]);
        setFlippedCards([]);
        if (matchedCards.length + 2 === cards.length) {
          setScore(score + 1);
          setTimeout(initializeGame, 1000); // Reset game after 1 second
        }
      } else {
        setTimeout(() => setFlippedCards([]), 1000); // Flip back after 1 second
      }
    }
  };

  return (
    <div>      
      <div className="grid">
        {cards.map((card, index) => (
          <div
            key={index}
            className={`grid-box card ${flippedCards.includes(index) || matchedCards.includes(index) ? 'flipped' : ''}`}
            onClick={() => handleCardClick(index)}
          >
            <div className="inner gridbox">
              <div className="front">
                <img src={card} alt="memory card" />
              </div>
              <div className="back"></div>
            </div>
          </div>
        ))}
      </div>
      <h2>Poäng: {score}</h2>
    </div>
  );
};

export default MemoryGame;