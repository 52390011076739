import React, { useState, useEffect } from "react";
import { connectHits } from "react-instantsearch-dom";
import SearchHit from "./SearchHit";
import { CircularProgress } from "@mui/material";

const SearchHits = (props) => {
  const [initialSearchDone, setInitialSearchDone] = useState(false);

  useEffect(() => {
    if (props.hits.length > 0) {
      setInitialSearchDone(true);
    }
  }, [props.hits]);
  const items = props.hits.map((hit) => (
    <SearchHit hit={hit} key={hit.objectID} />
  ));
  const onAddNew = props.onAddNew;
  const lessThanFive = items.length < 5;
  const extraTile = !props.isLoading ? (
    <div className="add-new grid-box" onClick={onAddNew}>Skapa ny</div>
  ) : (
    <div className="add-new grid-box">
      <CircularProgress />
    </div>
  );
  return (
    <div>
      <div className="image-grid">
        {items}
        {initialSearchDone && lessThanFive && extraTile}
      </div>
    </div>
  );
};

export default connectHits(SearchHits);
