import PrintIcon from '@mui/icons-material/Print';
import React from 'react';

const PrintButton = ({ onClick }) => {
  return (
    <button onClick={onClick} className="print-button no-print">
      <PrintIcon fontSize="inherit" />
      
    </button>
  );
};

export default PrintButton;
