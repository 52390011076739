import "./App.css";
import {React, useEffect} from 'react';
import BackButton from './BackButton';
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = () => {
    const navigate = useNavigate();
    const handleClose = () => {
        navigate(-1);
      };
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
  return (
    <div style={styles.body}>
      <div style={styles.container}>
      <BackButton onClick={handleClose} />
        <h1 style={styles.header}>Integritetspolicy för kritriket.se</h1>
        <p><strong>Introduktion</strong></p>
        <p>
          Välkommen till Kritriket.se! Vi värnar om din integritet och är engagerade i att skydda dina personuppgifter.
          Denna integritetspolicy beskriver hur vi samlar in, använder och skyddar din information när du besöker vår webbplats.
        </p>

        <h2 style={styles.subHeader}>Information vi samlar in</h2>
        <p><strong>Automatisk Information</strong></p>
        <p>Vi samlar in viss information automatiskt när du använder vår webbplats via Firebase Analytics:</p>
        <ul>
          <li>IP-adress</li>
          <li>Webbläsartyp och version</li>
          <li>Operativsystem</li>
          <li>Besökta sidor</li>
          <li>Tidsstämplar för besök</li>
          <li>Enhetsinformation (t.ex. modell och operativsystem)</li>
        </ul>
        <p>Observera att vi inte samlar in namn, e-postadresser eller annan personlig identifierbar information.</p>

        <h2 style={styles.subHeader}>Hur vi använder information</h2>
        <p>Vi använder den insamlade informationen för att:</p>
        <ul>
          <li>Förbättra vår webbplats och din användarupplevelse</li>
          <li>Analysera webbplatsens trafik och användning</li>
        </ul>

        <h2 style={styles.subHeader}>Delning av information</h2>
        <p>Vi delar inte din personliga information med tredje parter, förutom i följande fall:</p>
        <ul>
          <li>När det krävs enligt lag eller som svar på en rättslig begäran</li>
          <li>Med tjänsteleverantörer som hjälper oss att driva vår webbplats (t.ex. webbhotell, analysverktyg)</li>
        </ul>

        <h2 style={styles.subHeader}>Firebase Analytics</h2>
        <p>
          Vi använder Firebase Analytics för att förstå hur vår webbplats används och för att förbättra användarupplevelsen.
          Firebase Analytics samlar in information anonymt och rapporterar webbplatsens trender utan att identifiera enskilda besökare.
          För mer information om hur Firebase Analytics samlar in och behandlar data, besök 
          <a href="https://firebase.google.com/support/privacy" target="_blank" rel="noopener noreferrer">Firebase Privacy & Security</a>.
        </p>

        <h2 style={styles.subHeader}>Skydd av Information</h2>
        <p>Vi vidtar lämpliga tekniska och organisatoriska åtgärder för att skydda din information mot obehörig åtkomst, förlust, missbruk eller ändring.</p>

        <h2 style={styles.subHeader}>Användares rättigheter</h2>
        <p>Du har rätt att:</p>
        <ul>
          <li>Begära tillgång till den information vi har om dig</li>
          <li>Begära att vi rättar eller raderar din information</li>
          <li>Invända mot eller begränsa behandlingen av din information</li>
          <li>Begära dataportabilitet för de uppgifter du har gett oss</li>
        </ul>
        <p>För att utöva dina rättigheter, vänligen kontakta oss på <a href="mailto:info@kritriket.se">info@kritriket.se</a>.</p>

        <h2 style={styles.subHeader}>Ändringar i policyn</h2>
        <p>Vi kan komma att uppdatera denna integritetspolicy från tid till annan. Vi kommer att meddela dig om väsentliga ändringar genom att publicera den nya policyn på vår webbplats och uppdatera datumet längst ner i detta dokument.</p>

        <h2 style={styles.subHeader}>Kontaktinformation</h2>
        <p>Om du har några frågor eller klagomål angående denna integritetspolicy eller vår hantering av din information, vänligen kontakta oss på:</p>
        <p>E-post: <a href="mailto:info@kritriket.se">info@kritriket.se</a></p>

        <p><strong>Senast uppdaterad:</strong> 2024-05-15</p>
      </div>
    </div>
  );
};

const styles = {
  body: {    
    lineHeight: 1.6,
    margin: '20px',    
    marginBottom: '120px',
    padding: '0',
    color: '#333',
  },
  container: {
    maxWidth: '800px',
    margin: 'auto',
    padding: '20px',
    borderRadius: '5px',
  },
  header: {
    color: '#000000',
    fontSize: '2em',
  },
  subHeader: {
    fontSize: '1.4em',
    color: '#000000',
  },
  a : {
    color: '#FF0000',
  }
};

export default PrivacyPolicy;
