import React from 'react';
import "./App.css";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="bottom-link-wrapper no-print">
      <Link to='/about' className="bottom-link-link">Om Kritriket</Link>
      <Link to='/privacy-policy' className="bottom-link-link">Privacy policy</Link>
    </div>
  );
};

export default Footer;
 