import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import DefaultThumbnail from "./images/default_thumbnail.png";

const SearchHit = ({ hit }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const thumbnailImage = hit.thumbnailImage;
  useEffect(() => {
    const fetchImageUrl = async () => {
      try {
        const storage = getStorage();
        const imageRef = ref(storage, thumbnailImage);
        const url = await getDownloadURL(imageRef);
        setImageUrl(url);
      } catch (error) {
        console.error("Failed to load image", error);
        setImageUrl(DefaultThumbnail);
      }
    };

    fetchImageUrl();
  }, [thumbnailImage]);

  if (hit.type === 'category') {
    return (
      <Link to={`/category/${hit.objectID}`}>
        <div className="grid-box">
        {imageUrl ? (
            <img src={imageUrl} alt={hit.name} />
          ) : (
            <div>Loading...</div>
          )}
        </div>
        <div className="category-description">{hit.name}</div>
      </Link>
    );
  } else {
    return (
      <Link to={`/image/${hit.objectID}`}>
        <div className="grid-box">
          {imageUrl ? (
            <img src={imageUrl} alt={hit.name} />
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </Link>
    );
  }
};

export default SearchHit;
