import { signInWithGooglePopup, signOutUser } from "./Firebase";
import { useState, useEffect } from "react";
import { onAuthStateChangedListener } from "./Firebase";
import GoogleIcon from "@mui/icons-material/Google";

const SignIn = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener((currentUser) => {
      setUser(currentUser);
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, []);

  const logGoogleUser = async () => {
    try {
      const response = await signInWithGooglePopup();
      console.log(response);
    } catch (error) {
      console.error("Sign in failed", error);
    }
  };

  const handleSignOut = async () => {
    try {
      await signOutUser();
      console.log("Signed out successfully");
    } catch (error) {
      console.error("Sign out failed", error);
    }
  };

  return (
    <div>
      {user ? (
        <button onClick={handleSignOut} className="button">
          Logga ut
        </button>
      ) : (
        <button onClick={logGoogleUser} className="button">
          <GoogleIcon fontSize="inherit" />
          Logga in
        </button>
      )}
    </div>
  );
};
export default SignIn;
